import { apiAction } from './apiAction'
import objectToFormData from 'object-to-formdata'

export const POST_CURATOR_REQUEST = 'POST_CURATOR_REQUEST'
export const POST_CURATOR_SUCCESS = 'POST_CURATOR_SUCCESS'
export const POST_CURATOR_FAILURE = 'POST_CURATOR_FAILURE'

export const sendCuratorRequest = requestFormData => (dispatch, getState) => {
  return apiAction(dispatch, getState, {
    endpoint: '/api/user/addpet',
    method: 'POST',
    types: [POST_CURATOR_REQUEST, POST_CURATOR_SUCCESS, POST_CURATOR_FAILURE],
    body: objectToFormData(requestFormData)
  })
}
